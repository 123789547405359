@import url("../base.css");


.reviewbg{
    width:100%;
    /* height:240px; */
    height:320px;
    background-color:#252a2e;
    background-repeat: no-repeat;
    background-position: center; 
    position: static;
    background-size: 768px 320px;
    /* background-image: url('../../img/review-bg-one@2x.png'); */
    background-image: url('../../img/review-bg@3x.png');
    background-repeat: no-repeat;
    background-position: center; 
}