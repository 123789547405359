/* font - face */
/* 폰트 적용 ( 아리따돋음 )*/
@font-face {
    font-family: 'Arita-dotum-Bold_OTF';
    font-style: normal;
    font-weight: normal;
    src: url('../fonts/arita-dotum-Bold.eot');  /* IE9 - 11*/
    src: url('../fonts/arita-dotum-Bold.eot#?iefix') format("embedded-opentype"),   /* IE Fix for IE 6-8*/
      url('../fonts/Arita-dotum-Bold.otf'), /* safari */ 
      url('../fonts/AritaDotumBold.woff') format("woff"); /* IE 9-11 & All Modern Browsers */
 
  }
  
  @font-face {
    font-family: 'Arita-dotum-SemiBold_OTF';
    font-style: normal;
    font-weight: normal;
    src: url('../fonts/arita-dotum-SemiBold.eot');  /* IE9 - 11*/
    src: url('../fonts/arita-dotum-SemiBold.eot#?iefix') format("embedded-opentype"),  /* IE Fix for IE 6-8*/
    url('../fonts/Arita-dotum-SemiBold.otf'),  /* safari */ 
    url('../fonts/AritaDotumSemiBold.woff') format('woff'); /* IE 9-11 & All Modern Browsers */
  }
  
  
  @font-face {
    font-family: 'Arita-dotum-Medium_OTF';
    font-style: normal;
    font-weight: normal;
    src: url('../fonts/arita-dotum-Medium.eot');  /* IE9 - 11*/
    src: url('../fonts/arita-dotum-Medium.eot#?iefix') format("embedded-opentype"),  /* IE Fix for IE 6-8*/
      url('../fonts/Arita-dotum-Medium.otf'), /* safari */ 
      url('../fonts/AritaDotumMedium.woff') format('woff'); /* IE 9-11 & All Modern Browsers */
  }
  
  

  
  
  /* 폰트 적용 ( 애플 고딕 나눔 ) ( 리뷰 ) */
  @font-face {
    font-family: 'AppleSDGothicNeo-Bold';
    font-style: normal;
    src: url("../fonts/AppleSDGothicNeoB.ttf") format('truetype')
  }
  
  @font-face {
    font-family: 'AppleSDGothicNeo-Regular';
    font-style: normal;
    src: url("../fonts/AppleSDGothicNeoR.ttf") format('truetype')
  }

  /* 기본 값 초기화 */
  *{
    margin:0 auto;
    padding:0 auto;
  }

  *:focus { 
    outline:none; 
  }  

  h1,h2,h3,h4,h5,h6 {
    font-size:100% 
  }

/* 좌우 border */
.solid {
  border:0.5px 
  solid #eeeeee;
  border-top-style: none;
  border-bottom-style: none;
}

.section-contents{
  width:90%;
  height:100%;
}

.indicate{
  width:1%;
  height:54%;
  padding-right:40px;
  padding-top:23%;
  float:right !important;
}

.indicateOn{
  width:12px;
  height:12px;
  float:left;
}

.indicateOff{
  width:12px;
  height:12px;
  float:left;
}


.contents-left{
  width:50%;
  height:95%;
  float:left;
  
}

.contents-text{
  width:85%;
  height:520px;
  padding-top:10%;
  word-break: keep-all;
  
}

.contents-text h1{
  font-family:Arita-dotum-Medium_OTF;
  font-size:32px;
  Line-height:1.38;
  font-weight: 300;
  color:#000000;

}
.contents-text h2{
  font-family:Arita-dotum-Medium_OTF;
  font-size:32px;
  Line-height:1.38;
  font-weight: 600;
  color:#000000;
}

.contents-text h3{
  font-family:Arita-dotum-Medium_OTF;;
  font-weight: 400;
  font-size:16px;
  line-height: 1.63;
  opacity: 0.7;
  padding-top:40px;
  color:#000000;
  width: 92%;
  float: left;
  font-weight: 300;
}

.red{
  color:#ff5543;
}

.white{
  color:#ffffff;
}

.contents-contents{
  text-align:left;
  color:#000000;
  padding-top:150px;

}

.contents-right{
  width:50%;
  height:95%;
  float:right;
  text-align: center;
  vertical-align: middle;
  display:table-cell;
  padding: 0 auto;
  margin: 0 auto;
}


.appdownload{
  display: none !important;
}

.size{
  width:100%;
  height:830px;   
}

.link{
  cursor: pointer;
}

img { 
  border:0 
}r


.terms{
  max-width: 600px;
  margin:0 auto;
  padding: 20px;
}

.terms h2{
  margin-bottom: 20px;
  font-size: 1.375rem;
  line-height: 1;
  font-weight:600;
}

.terms h3{
  margin-top:20px;
  margin-bottom:10px;
  line-height:1;
  font-size:15px;
  font-weight:600;
}

.terms h4{
  font-weight:500;
  font-size:14px;
  float:left;
}

.terms p {
  margin-top: 0;
  margin-bottom: 1em;
  font-size: .8125rem;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-size: .8125rem;
}

.ol{
  list-style-type: none;
}

.ul{
  list-style-type: circle
}

.table{
  text-align:left;
  border-bottom:  0.5px solid #ddd;
}

th, td{
  text-align: left;
  border: 0.5px solid #ddd ;
  padding: 7px 10px;
  
}

table {
  width: 100%;
  text-align: center;
  border: 0.5px solid #ddd;
  border-bottom:  0.5px solid #ddd;
  border-collapse: collapse;
}


/* display: none*/
.h3-mobile ,.contents-mobile, .mobile-sidebar, .mobile-slidebar, .section2_title_mobile
, .section2_tablet, .section3_tablet, 
.section4_mobile,.section4_tablet, .section3_mobile  , .section6_text_mobile,.section5_title_mobile{
  display:none;
}
