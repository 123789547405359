  /* Device = mobile , Screen = 414px to 767px */
  @media (min-width: 0px) and (max-width: 767px) {    

    .size{
        width:100%;
        height:358px !important;   
      }
      
    .headersection{
        width: 100%;
        height: 100% !important;
        background-image: url('../../img/spot-bg.png');
        background-size: 100% 358px !important;
        /* background-size: 100% 100%; */
        display: block;
        margin: 0px auto;
        height: 0px auto;
        background-repeat: no-repeat;
    }

    .app_download, .appdownlaod{ 
        display: none;
    }
 
    .content{
     width:280px;
     height:100%;
     text-align: center;
 }
    .title {
        width:100% !important;
        padding-top: 50px;
    }

    .tocha_header_title_mobile{
        display: block;
        width: 280px;
        height: 60px;
        font-family: Arita-dotum-Bold_OTF;
        font-size: 28px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.07;
        letter-spacing: normal;
        text-align: center;
        color: #2e2221;
    }

    .contents {
        width:100%;
        padding-top:25px;
    }
    .tocha_header_contents{
        padding-top:25px;
        width: 280px;
        height: 40px;
        font-family: Arita-dotum-Medium_OTF;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: center;
        color: #2e2221;
    }
    .char {
        width: 100px;
        height: 100px;
        padding-top: 83px;
        padding: 83px 0 0 0 !important;
        text-align: center;
        display: block;
    }

    .char img {
        width: 100px;
        height: 100px;
        display:inline-block;
    }

    .app_download_mobile{
        display: block;
        padding: 44px 0 30px 0  !important;
        display: table !important;
    }

    .btn_app {
        width: 260px;
        height: 48px;
        border-radius: 4px;
        border: solid 1.5px #bab68e;
        opacity: 0.7;
        display: inline-block;

    }

    .btn_text {
        font-family: Arita-dotum-SemiBold_OTF;
        width: 112px;
        font-size: 16px;
        line-height: 1;
        text-align: center;
        color: #2e2221;
        padding: 16px 74px 16px 74px;
    }

    .section1{
          height:704px !important;
       }

   .section1_content{
       width: 280px !important;
   }    

   .section1_left{
       width: 100% !important;
   }

   .section1_title{
       padding-top: 172px !important;
   }

   .section1_title p{
    word-break: keep-all;
    width: 280px;
    height: 90px;
    font-family: Arita-dotum-Bold_OTF !important;
    font-size: 28px !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: normal;
    text-align: center;
    color: #2e2221;
   }

   .section1_text{
    width: 100% !important;
    /* height: 180px !important; */
    padding-top: 25px !important;
    height:208px !important

   }

   .section1_text p{
    width: 280px; 
    font-family: Arita-dotum-Medium_OTF;
    font-size: 14px !important;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: center;
    color: #2e2221;   
   }

   .tocha_section1_mobile {
       display: block !important;
   }

   .tocha_taro{
    width: 100% !important;
    height: 100% !important;
   }

   .section2{
       height:630px !important;
   }

   .section2_content{
    width: 100% !important;
    }   

    .section2_left{
        width: 100% !important;
        height:302px;
    }

    .section2_title{
        height: 92px;
        padding-top:200px;
    }

    .section2_title_mobile{
        display: block;
        width: 280px;
        height: 0 !important;
        font-family: Arita-dotum-Bold_OTF !important;
        font-size: 28px ;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.07;
        letter-spacing: normal;
        text-align: center;
        color: #2e2221;
        padding-top: 50px !important;
        word-break: keep-all;
    }

    .section2_title_mobile p {
        font-family: Arita-dotum-_OTF;
        font-size: 28px !important;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.15;
        letter-spacing: normal;
        color: #ffffff;
        float:left;
        width:100%;
    }

    .section2_text {
        width: 100% !important; 
        /* height: 140px !important; */
        padding-top: 25px !important;
    }

    .section2_text p {
        width: 280px;
        /* height: 140px !important; */
        opacity: 0.7;
        font-family: Arita-dotum-_OTF;
        font-size: 14px !important;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
    }

    .tocha_section2{
        text-align: center;
    }

    .tocha_1 {
        width: 264px !important;
        height: 275px !important;
        padding-top: 50px !important;
    }


    .section3{
        /* height:590px !important; */
        height: 588px !important;
    }


    .section3_mobile{
        display: block !important;
        width: 100% !important;
        /* height: 84px !important; */
        padding-top:25px;
        float: left;
        text-align: center;
    }

    .section3_mobile p {
        display: block !important;
        font-size: 14px !important;
        width: 100%;
        font-family:Arita-dotum-Medium_OTF;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        color: #2e2221;
    }

    .section3_content{
     width: 100% !important;
    }   
 
     .section3_left{
         width: 100% !important;
     }
 
     .section3_title{
         height: 92px;
         padding-top:200px;
     }
 
     .section3_title{
         display: block;
         width: 280px;
         height: 0 !important;
         font-family: Arita-dotum-Bold_OTF !important;
         font-size: 28px !important;
         font-stretch: normal;
         font-style: normal;
         line-height: 1.07;
         letter-spacing: normal;
         text-align: center;
         color: #2e2221;
         padding-top: 50px !important;
         word-break: keep-all;
     }
 
     .section3_title p {
         font-family: Arita-dotum-_OTF;
         font-size: 28px !important;
         font-stretch: normal;
         font-style: normal;
         line-height: 1.07 !important;
         letter-spacing: normal;
         color: #ffffff;
         float:left;
         width:100%;
     }
 
     .section3_text {
         width: 100% !important; 
         height: 76px !important;
         padding-top: 25px !important;
     }
 
     .section3_text p {
         width: 280px;
         opacity: 0.7;
         font-family: Arita-dotum-_OTF;
         font-size: 14px !important;
         font-weight: 500;
         font-stretch: normal;
         font-style: normal;
         line-height: 1.43;
         letter-spacing: normal;
         text-align: center;
         color: #ffffff;
     }
 
     .tocha_section3{
         text-align: center;
 
     }
 
     .tocha_3 {
         width: 264px !important;
         height: 325px !important;
         padding-top: 50px !important;
     }

     .section4{
         /* height:590px !important; */
        height: 588px !important;
        }

     .tocha_section4{
         text-align: center;
     }

     .tocha_4 {
        width: 264px !important;
        height: 325px !important;
        padding-top: 50px !important;
    }

    
    .section4_left{
        width: 100% !important;
    }

    .section4_mobile{
        display: block !important;
        width: 100% !important;
        /* height: 84px !important; */
        padding-top:25px;
        float: left;
        text-align: center;
    }

    .section4_mobile p {
        display: block !important;
        font-size: 14px !important;
        width: 100%;
        font-family:Arita-dotum-Medium_OTF;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        color: #2e2221;
    }
     .section5_title_mobile{
         display: block;
         width: 280px;
         height: 0 !important;
         font-family: Arita-dotum-Bold_OTF !important;
         font-size: 28px !important;
         font-stretch: normal;
         font-style: normal;
         line-height: 1.07;
         letter-spacing: normal;
         text-align: center;
         color: #ffffff;
         padding-top: 50px !important;
         word-break: keep-all;
     }
 
     .section5_title_mobile p {
         font-family: Arita-dotum-Bold_OTF !important;
         font-size: 28px !important;
         font-stretch: normal;
         font-style: normal;
         line-height: 1.07;
         letter-spacing: normal;
         color: #ffffff;
         height:90px;
     }
 
     .section5_text {
         width: 100% !important; 
         height: 40px !important;
         padding-top: 115px !important;
     }
 
     .section5_text p {
         width: 280px;
         height: 40px !important;
         opacity: 0.7;
         font-family: Arita-dotum-_OTF;
         font-size: 14px !important;
         font-weight: 500;
         font-stretch: normal;
         font-style: normal;
         line-height: 1.43;
         letter-spacing: normal;
         text-align: center;
         color: #ffffff;
     }
 
     .tocha_section5{
         text-align: center;
         
 
     }
 
     .tocha_5 {
         width: 264px !important;
         height: 325px !important;
         padding-top: 50px !important;
     }

     .section5_content, .section5_left {
         width:100% !important;
     }



    .section6{
        height:570px !important;
    }
 
    .section6_content{
     width: 100% !important;
    }   
 
     .section6_left{
         width: 100% !important;
         height:195px;
     }
 
     .section6_title{
         height: 92px;
         padding-top:200px;
     }
 
 
 
     .section6_title{
         display: block;
         width: 280px;
         height: 0 !important;
         font-family: Arita-dotum-Bold_OTF !important;
         font-size: 28px !important;
         font-stretch: normal;
         font-style: normal;
         line-height: 1.07;
         letter-spacing: normal;
         text-align: center;
         color: #2e2221;
         padding-top: 50px !important;
         word-break: keep-all;
     }
 
     .section6_title p {
         font-family: Arita-dotum-_OTF;
         font-size: 28px !important;
         font-stretch: normal;
         font-style: normal;
         line-height: 1.07;
         letter-spacing: normal;
         color: #ffffff;
         float:left;
     }
 
     .section6_text_mobile {
         display: block;
         width: 100% !important; 
         height: 40px !important;
         padding-top: 25px !important;
         float: left;
     }
 
     .section6_text_mobile p {
        width: 280px;
        height: 60px;
        font-family: Arita-dotum-Medium_OTF_OTF;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: center;
        color: #2e2221;
     }
 
     .tocha_section6{
         text-align: center;
         height:570px;        
 
     }

     .section5{
         height:580px!important;
     }
 
     .tocha_6 {
         width: 264px !important;
         height: 325px !important;
         padding-top: 50px !important;
     }

     .section7{
        height:591px !important;
    }
 
    .section7_content{
     width: 100% !important;
    }   
 
     .section7_left{
         width: 100% !important;
     }
 
     .section7_title{
         width: 100% !important;
         height: 92px;
         padding-top:200px;
     } 
 
     .section7_title{
         display: block;
         width: 280px;
         height: 0 !important;
         font-size: 28px !important;
         font-stretch: normal;
         font-style: normal;
         line-height: 1.07;
         letter-spacing: normal;
         text-align: center;
         color: #2e2221;
         padding-top: 50px !important;
         word-break: keep-all;
     }
 
     .section7_title p {
         font-family: Arita-dotum-Bold_OTF !important;
         font-size: 28px !important;
         font-weight: normal !important;
         font-stretch: normal;
         font-style: normal;
         line-height: 1.15;
         letter-spacing: normal;
         color: #ffffff;         
     }
 
     .section6_text {
         width: 100% !important; 
         height: 40px !important;
         padding-top: 25px !important;
     }
 
     .section6_text p {
         width: 280px;
         height: 40px !important;
         opacity: 0.7;
         font-family: Arita-dotum-Bold_OTF !important;
         font-size: 14px !important;
         font-weight: 500;
         font-stretch: normal;
         font-style: normal;
         line-height: 1.43;
         letter-spacing: normal;
         text-align: center;
         color: #ffffff;
     }
 
     .tocha_section6{
         text-align: center;
     }
 
     .tocha_6 {
         width: 264px !important;
         height: 325px !important;
         padding-top: 50px !important;
     }

     .slick-slider {
         padding-top: 50px;
     }

     .section7 {
         height:438px !important;
     }
     
     .section7_title p{
         font-size: 28px !important;
     }

     .reviewbg_mobile {
         display: block;
        background-image: url('../../img/review-bg@3x.png');
        width: 100%;
        height: 153px;
        background-size: 100% 153px;
     }

     .footer{
         height: 361px !important;
     }

     .footer-contents {
         width: 100% !important;
     }

     .footer_tablet{
         display: block !important;
         text-align: center;
     }

     .footer_left{
        width: 280px !important;
        height: 281px;
        display: inline-block;
        padding-left:20px;
     }

     .footer_text{
         padding-top: 40px !important;
     }

     .footer_left p{
        width: 280px;
        opacity: 0.5;
        font-family: Arita-dotum-_OTF;
        font-size: 12px !important;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        color: #ffffff;
        text-align: left;
     }

     .office{
         padding-top:20px !important;
     }

     .footer_right{
         width:100% !important;
         /* height:102px !important; */
         float: left;
     }

     .adress_title{
         width:50px;
         padding-top: 16px !important;

     }

     .adress_title p{
         padding-top: 4px !important;
         width:100%;
         float:left;
     }

     .adress_info{
         padding-top:16px !important;
         width: 210px;
         float:center;
        
     }

     .adress_info p {
        padding-top: 4px !important;
        padding-left: 4px !important;
        width: 280px;
        opacity: 0.5;
        font-family: Arita-dotum-_OTF;
        font-size: 12px !important;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        color: #ffffff;
     }

     .sns {
         padding-top: 40px !important;
     }

     .instagram{
         padding-left: 20px !important;
     }

     .font_arita{
        width: 280px !important;
        /*height: 24px; */
        opacity: 0.3 !important;
        /* font-family: Arita-dotum-_OTF; */
        font-size: 10px !important;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2 !important;
        letter-spacing: normal;
        color: #ffffff;
        text-align: left;
     }
 





  /*  none */
  .tocha_header_title, .tocha_section1, .tocha_section1_tablet, 
  .section2_title,.reviewbg, .section6_text,
   .footer_desktop ,.appdownlaod7,.section5_title
   ,.section3_text p,.section3_text{
    display: none !important;
}
    }

  