@import url("../base.css");

.section7{
    width:100%;
    /* height:732px; */
    height:652px;
    background-color:#252a2e;
}

.section7_content{
    width:1150px;
    height:100%;
}



.section7_title{
    width: 520px;
    height: 46px;
    padding-top:200px;
}

.section7_title p{
        font-family: Arita-dotum-Bold_OTF;
        font-size: 40px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.15;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
}

.review_padding{
    padding-top:50px;
}
.review {
    width: 260px;
    height: 200px;
    border-radius: 4px;
    background-color: #eeeeee;
  }

.review_max{
    width: 260px;
    height: 220px;
    border-radius: 4px;
    background-color: #eeeeee;
}

.review_title{
    width:220px;
    height:90px;
}

.rv_title{
    font-family: 'AppleSDGothicNeo-Bold';
    font-size: 14px;
    line-height: 1;
    color: #000000;
    float: left;
    padding-top: 30px;
}


.rv_name {
    font-family: 'AppleSDGothicNeo-Regular';
    font-size: 14px;
    opacity: 0.3;
    text-align: right;
    color: #000000;
    padding-left: 22px;
    padding-top: 30px;
  }

  .star {
    width: 16px;
    height: 16px;
    float: left;
    padding-right: 4px;
    padding-top: 10px;
  }

  .rv_contents{
    width:220px;
    height:80px;
  }

  .rv_contents p{
    font-family: 'AppleSDGothicNeo-Regular';
    font-size: 14px;
    line-height: 1.43;
    color: #000000;
    text-align: left;
  }

  .app_download_footer{
    display: none;
  }

  .tree{
    z-index: -1;
  }

  .appdownlaod7{
    width: 100%;
    padding-top: 50px;
    height: 48px;
    text-align: center;
    display: inline-block;

 }

 .apple_btn7{
  width: 161px;
  height: 48px;
  object-fit: contain;
  text-align: center;
  padding-left:379px;
  float:left;

 }

 .google_btn7{
    width: 162px;
    height: 48px;
    object-fit: contain;
    /* float: left; */
    padding-left: 20px;
    padding-right: 212px;
    float:left;
 }

