  /* Device = Tablets, Ipads , Screen = 768px to 1023px */
  @media (min-width: 768px) and (max-width: 1150px) {  

   .headersection{
       width:100%;
       height:859px;
   }

   .content{
    width:688px;
    height:100%;
    text-align: center;
}

    .title{
        width: 688px;
        height: 40px;
        padding-top:200px;
    }

    .tocha_header_contents{
        font-family: Arita-dotum-Medium_OTF;
        font-size: 21px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: center;
        color: #2e2221;
    }

    .appdownlaod{
        display:none;
    }

    .app_download{
        padding-top: 30px !important;
        display:table !important;
    }

    .btn_app {
        width: 260px;
        height: 48px;
        border-radius: 4px;
        border: solid 1.5px #bab68e;
        opacity: 0.7;
        display: inline-block;
      }

    .btn_text {
        font-family: Arita-dotum-SemiBold_OTF;
        width:112px;
        font-size: 16px;
        line-height: 1;
        text-align: center;
        color: #2e2221;
        padding: 16px 74px 16px 74px;
  }

  .char{
    width:688px;
    /* display: inline-block; */
    padding:193px 0 0 0;
}

    .section1{
        height:1063px !important;
        /* height:1075px !important; */
    }

    .section1_content{
        /* width:688px !important; */
        width:100% !important;
        text-align: center;
    }

    .section1_left {
        width:100% !important;
        /* width:688px !important; */
        /* height:92px; */
        height:494px;
        text-align: center;
    }

    .section1_title{
        padding-top: 100px !important;
    }

    .section1_title p{
        text-align: center !important;
        float: inherit !important;
    }

    .section1_text{
        width: 100% !important;
        height: 252px !important;
        padding-top: 50px;
        float: inherit !important;
    }

    .section_right{
        display: none;
    }

    .section1_text p{
        font-size: 21px !important;
    }

    .section_right{
        width:100%;
        height:494.2px;
        float:left;
    }

    .tocha_taro {
        width: 768px !important;
        height: 568.8px !important;
        display:inline-block;
    }

    .tocha_section1{
        display: none;
    }

    .tocha_section1_tablet{
        display: block !important;
        width:100%;
        height:1014px;
        /* height:100%; */
        /* float:left; */
        text-align:center;
      
    }

    .tocha_section6_tablet{
        display: block !important;
        width:100%;
        height:968px;
        /* height:100%; */
        /* float:left; */
        text-align:center;
    }

    .section2{
        width:100%;
        height:1052px !important;
        /* height:1059px !important; */
    }

    .section2_content{
        width:100% !important;
    }

    .section2_left {
        width:100% !important;
    }

    .section2_title{
        text-align: center;
        padding-top: 100px !important;
    }

    .section2_title p {
        width:100% ;
    }

    .section2_tablet{
        display: block !important;
        width: 100% !important;
        padding-top:50px ;
        /* height: 196px !important; */
        text-align: center;
    }

    .section2_tablet p {
        display: block !important;
        width: 100%;
        font-size: 21px !important;
        opacity: 0.7;
        font-family:Arita-dotum-Medium_OTF;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        color: #ffffff;
    }


    .tocha_1{
        width: 500px;
        height: 521px !important;
        display: inline-block !important;
        padding-top: 100px;
    }

    .section3{
        width: 100%;
        height: 1042px !important;
    }
    .section3_content{
        width: 100% !important;
    }

    .section3_left {
        width: 100% !important;
        height:326px;
        text-align: center;
    }

    .section4_left {
        width: 100% !important;
        /* height:326px; */
        text-align: center;
    }
    
    
    .section3_title {
        display: inline-block;
        padding-top: 100px !important;
    }

    .section3_tablet{
        display: block !important;
        width: 100% !important;
        /* height: 84px !important; */
        height: 54px !important;
        padding-top:50px ;
    }

    .section3_tablet p {
        display: block !important;
        font-size: 21px !important;
        width: 100%;
        opacity: 0.7;
        font-family:Arita-dotum-Medium_OTF;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;

    }


    .section4_tablet{
        display: block !important;
        width: 100% !important;
        /* height: 84px !important; */
        height: 54px !important;
        padding-top:50px ;
    }

    .section4_tablet p {
        display: block !important;
        font-size: 21px !important;
        width: 100%;
        font-family:Arita-dotum-Medium_OTF;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        color: #2e2221;

    }

    .section4_mobile{
        display: none;
    }

    .tocha_3{
        width:500px;
        height: 616px !important;
        padding-top:100px;
    }

    .section4{
        width:100%;
        height:1014px !important;
    }

    .tocha_4{
        height:616px !important;
        padding-top: 100px;

    }

    .section5{
        width:100%;
        height: 1014px !important;;
    }

    .section5_content{
        width: 100% !important;
    }

    .section5_left {
        width: 100% !important;
        text-align: center;
    }
    
    .section5_title {
        display: inline-block;
        padding-top: 100px !important;
    }

    .section5_text{
        width: 100% !important;
        height: 56px !important;

    }

    .section5_text p {
        font-size: 21px !important;
    }
    .tocha_5{
        height: 616px !important;
        padding-top: 97px;
    }

    .section6{
        width:100%;
        height: 968px !important;;
    }

    .section6_content{
        width: 100% !important;
    }

    .section6_left {
        width: 100% !important;
        text-align: center;
    }
    
    .section6_title {
        display: inline-block;
        padding-top: 100px !important;
        height: 46px !important;
    }


    .section6_text{
        width: 100% !important;
        height: 100% !important;

    }

    .section6_text p {
        font-size: 21px !important;

    }
    .tocha_6{
        height: 616px !important;
        padding-top: 100px;
    }

    .section7{
        height: 632px !important;
    }


    .section7_content{
        /* width: 100% !important; */
        /* width:80% !important; */
        width: 560px !important;
    }

    .section7_left {
        width: 100% !important;
        text-align: center;
    }
    
    .section7_title {
        display: inline-block;
        padding-top: 100px !important;
        height: 46px !important;
        width: 100% !important;
    }


    .section7_text{
        width: 100% !important;
        height: 56px !important;

    }

    .section7_text p {
        font-size: 21px !important;
    }

    .multiple-items{
        width: 100%;
    }

    .footer {
      width: 100%;
      height: 414px !important;
    }
    .footer-contents{
        width : 100% !important;
    }

    .footer_desktop{
        display: none;
    }

    .footer_tablet{
        display: block !important;
        width:100%;
        /* text-align: center; */
    }

    .footer_left{
        width: 718px !important;
        float:left;
    }

    .footer_text{
        padding: 50px 0px 0px 50px !important;
    }

    .adress_title{
        padding-top: 22px !important;
    }

    .adress_title p{
        padding-top: 0px !important;
    }

    .adress_info{
        padding-top:22px !important;
    }

    .adress_info p{
        padding-top: 0px !important;
    }
    .footer_right{
        width: 100% !important;
    }

    .sns{
        padding-top: 50px !important;
        opacity: 1 !important;
    }

    .font_arita{
        padding-top:40px !important;
    }

    .app_download_footer {
        padding-top: 50px !important;
        display:table !important;
        display: block;
    }

    .btn_app_footer {
        width: 260px;
        height: 48px;
        border-radius: 4px;
        border: solid 1.5px #3f4247;
        opacity: 0.7;
        display: inline-block;
    }

    .btn_text_footer{
        font-family: Arita-dotum-SemiBold_OTF;
        width: 112px;
        font-size: 16px;
        line-height: 1;
        text-align: center;
        color: #ffffff;
        padding: 16px 74px 16px 74px;
    }






    /* none */
    .app_download_mobile, .tocha_section1_mobile, .section2_text, .section3_text , .appdownlaod7{
        display: none !important;
    }
}