@import url("../base.css");

.footer{
    width:100%;
    height:378px;
    background-color:#3f4247;
    border-left-color: #dddddd;
    border-right-color: #dddddd;
}

.footer-contents{
    width:1150px;
}



.footer_left{
    width: 50%;
    height: 100%;
    float: left;
}

.footer_left p{    
    opacity: 0.5;
    font-family: Arita-dotum-Medium_OTF;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #ffffff;
  
}

.footer_right{
    width: 50%;
    height: 100%;
    float: left;
}

.footer_right p{
    opacity: 0.5;
    font-family: Arita-dotum-Medium_OTF;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #ffffff;
  
}


.footer_text{
    width: 100%;
    padding-top:80px;
}

.copyright{
    float:left;
    color:3f4247;
    width:100%;
}

.office{
    float:left;
    color:3f4247;
    width:100%;
    padding-top:24px;
}

.sns{
    width:100%;
    float:left;
    padding-top: 44px;
}

.facebook {
    width:30px;
    height:30px;
    float:left;
}

.instagram {
    width:30px;
    height:30px;
    float:left;
    padding-left:30px;
}

.font_arita{
    width:100%;
    padding-top: 44px;
    float:left; 
    opacity: 0.3;
    font-family: Arita-dotum-thin_OTF !important;
    font-size: 12px ; 
    font-weight: 500;
    color: #ffffff;

}

.user{
    width:47%;
    float:left;
    
}

.user h5{
    float:left;
    padding-left:20px;
    opacity:1;
    font-weight: bold;
    font-family: Arita-dotum-Medium_OTF;
    font-weight:500; 
    color:#ffffff;
    font-size:12px;
    line-height:1.67;
    cursor: pointer;
}

h6{
    font-family: Arita-dotum-Medium_OTF;
    font-weight:500; 
    color:#ffffff;
    font-size:14px;
    opacity: 0.5;
    padding-top:60px;
    
}
.adress_and_moditt{
    width:100%;
}

.adress{
    width:75%;
    float: left;
}

.adress_title{
    width:66px;
    padding-top:66px;
    float: left;
    height:100%;
}
.adress_title p{
    padding-top:18px;
}

.adress_info{
    padding-top:66px;
    float: left;
    height:100%;
}

.adress_info p {
    padding-top:18px;
    padding-left: 74px;
    font-family: Arita-dotum-Medium_OTF;
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    opacity: 1;
    line-height: 1.5;
}

.adress h5{
    font-family: Arita-dotum-Medium_OTF;
    font-weight:500; 
    color:#ffffff;
    font-size:12px;
    opacity: 0.5;
    line-height:1.67;
    padding-top:20px;
}

h5{
    font-family: Arita-dotum-Medium_OTF;
    font-weight:500; 
    color:#ffffff;
    font-size:12px;
    opacity: 0.5;
    line-height:1.67;
}

hr{
    opacity: 0.2;
    width:100%;
    background-color: #ffffff;
}


.opacity{
    opacity: 0.5 !important;
    line-height: 1.67 !important; 
    font-weight: 500 !important;
}



/* display : none */
.mobile-copyright {
    display:none;
}

.footer_tablet{
    display: none;
}
