@import url("../base.css");

.section4{
    width:100%;
    height:766px;
    background-color:#f7a59d;
}

.section3_content{
    width:1150px;
    height:100%;
}

.section4_left {
    width:50%;
    float:left;
}

.section3_right {
    width:50%;
    float:left;
}

.section3_title{
    height: 92px;
    padding-top:200px;
}

.section3_title p{
    font-family: Arita-dotum-Bold_OTF;
    font-size: 40px ;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.15;
    letter-spacing: normal;
    color: #2e2221;
    float:left;
}

.section3_text{
    width:520px;
    height: 288px;    
    padding-top: 50px;
    float: left;
}

.section3_text p {
    font-family: Arita-dotum-Medium_OTF;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #2e2221;
}
.tocha_section4{
    padding-top:151px;
}

.tocha_4{
    width: 500px;
    height: 615px;
    /* float:left; */
}
