@import url("../base.css");

.section5{
    width:100%;
    height:766px;
    background-color:#56579a;
}

.section5_content{
    width:1150px;
    height:100%;
}

.section5_left {
    width:50%;
    float:left;
}

.section5_right {
    width:50%;
    float:left;
}

.section5_title{
    height: 92px;
    padding-top:200px;
}

.section5_title p{
    font-family: Arita-dotum-Bold_OTF;
    font-size: 40px ;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.15;
    letter-spacing: normal;
    color: #ffffff;
    float:left;
}

.section5_text{
    width:520px;
    height: 288px;    
    padding-top: 50px;
    float: left;
}

.section5_text p {
    font-family: Arita-dotum-Medium_OTF;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #ffffff;
}
.tocha_section5{
    padding-top:151px;
}

.tocha_5{
    width: 500px;
    height: 615px;
    /* float:left; */
}

