@import url("../base.css");

.section6{
    width:100%;
    height:698px;
    background-color:#b2c3ab;
}

.section6_content{
    width:1150px;
    height:100%;
}

.section6_left {
    width:50%;
    float:left;
}

.section6_right {
    width:50%;
    float:left;
}

.section6_title{
    
    height: 92px;
    padding-top:200px;
}

.section6_title p{
    font-family: Arita-dotum-Bold_OTF;
    font-size: 40px ;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.15;
    letter-spacing: normal;
    color: #2e2221;
    float:left;
    word-break: keep-all;

}

.section6_text{
    font-family: Arita-dotum-Medium_OTF;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #2e2221;;
    padding-top: 50px;
    float: left;
}

.section6_text p {
    font-family: Arita-dotum-Medium_OTF;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #2e2221;
}
.tocha_section6{
    padding-top:83px;
}

.tocha_6{
    width: 500px;
    height: 615px;
    /* float:left; */
}

.tocha_section6_tablet{
    display: none;
}
