@import url("../base.css");

.section1{
    width:100%;
    height:830px;
    background-color:#dcd8b6;
}

.section1_content{
    width:1150px;
    height:100%;
}

.section1_left {
    width:50%;
    float:left;
}

.section1_right {
    width:50%;
    float:left;
}

.section1_title{
    height: 92px;
    padding-top:200px;
}

.section1_title p{
    font-family: Arita-dotum-Bold_OTF;
    font-size: 40px ;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.15;
    letter-spacing: normal;
    color: #2e2221;
    float:left;
}

.section1_text{
    width:520px;
    height: 288px;    
    padding-top: 50px;
    float: left;
}

.section1_text p {
    font-family: Arita-dotum-Medium_OTF;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #2e2221;
}

.sectionimg1{
    width:inherit;
    max-width:100%;
    width:510px;
    height:520px;
    padding:100px 0px;
}
.tocha_section1{
    padding-top:460px;
}

.tocha_taro{
    width: 500px;
    height: 370px;
    /* float:left; */
}


.tocha_section1_tablet, .tocha_section1_mobile, .app_download_mobile{
    display: none;
}


