@import url("../base.css");

.section2{
    width:100%;
    height:766px;
    background-color:#2b2e21;
}

.section2_content{
    width:1150px;
    height:100%;
}

.section2_left {
    width:50%;
    float:left;
}

.section2_right {
    width:50%;
    float:left;
}

.section2_title{
    height: 92px;
    padding-top:200px;
}

.section2_title p{
    font-family: Arita-dotum-Bold_OTF;
    font-size: 40px ;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.15;
    letter-spacing: normal;
    color: #ffffff;
    float:left;
}

.section2_text{
    width:520px;
    height: 288px;    
    padding-top: 50px;
    float: left;
}

.section2_text p {
    font-family:Arita-dotum-Medium_OTF;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #ffffff;
}
.tocha_section2{
    padding-top:244px;
}

.tocha_1{
    width: 500px;
    height: 522px;
    /* float:left; */
}
