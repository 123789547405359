@import url("../base.css");

.headersection{
    width:100%;
    height:859px;
    background-size: 1920px 859px;
    background-image: url('../../img/spot-bg-one@3x.png');
    /* background-repeat: no-repeat; */
    background-position: center; 
    position: static;
}

.appdownlaod{
    width: 100%;
    padding-top:50px;
    /* margin-left:213px;
    margin-right:212px; */
}

.app_download{
    display: none;
}

.apple_btn {
    width: 161px;
    height: 48px;
    object-fit: contain;
    float:left;
    padding-left:213px;
  
}
.google_btn {
    width: 162px;
    height: 48px;
    object-fit: contain;
    float:left;
    padding-left:20px;
    padding-right:212px;
  
}
.content{
    width:768px;
    height:100%;
}

.title{
    width: 520px;
    height: 40px;
    padding-top:200px;
}

.tocha_header_title{
    font-family: Arita-dotum-Bold_OTF;
    font-size: 40px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #2e2221;

}
.contents{
    width: 520px;
    height: 64px;
    padding-top:50px;
}

.tocha_header_contents{
    font-family: Arita-dotum-Medium_OTF;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #2e2221;
}

.char{
    width:240px;
    height:214px;
    /* float:left; */
    padding:193px 264px 0px 264px;
}

.tocha_header_title_mobile{
    display: none;
}
/* .contents-text-top{
    width:570px;
    height:520px;
    margin: 50px 100px;
    word-break: keep-all;
  }

.top-app{
    width:100%;
    height:25%;
    padding-top:60px;
}

.appstore, .googleplay{
    width:50%;
    float:left;
    width:186px;
    height:46px;
    padding-right:10px;
    cursor: pointer;
} 

.sectionimg{
    width:inherit;
    max-width:100%;
    width:510px;
    height:520px;
    padding:100px 100px;    
}

.top-text{
    font-family: 'Noto Serif KR', sans-serif;
    font-weight:300; 
    font-size:32px;
    Line-height:1.38;
    color:#2e2221;

}

.top-title{
    font-family: 'Noto Serif KR', sans-serif;
    font-weight:600; 
  font-size:32px;
  Line-height:1.38;
  color:#2e2221;
}

.top-contetns{
    font-family: 'Noto Serif KR', sans-serif;
    font-weight:400; 
    font-size:16px;
    line-height: 1.63;
    opacity: 0.7;
    padding-top:40px;
    color:#2e2221;
}


.appdownload{
    display:none;
} */