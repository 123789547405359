@charset 'UTF-8';


/* Dots */
.slick-dotted.slick-slider
{
    margin-bottom: 30px;
    text-align: center;
    
}

.slick-dots li
{
    position: relative;

    display: inline-block;

    width: 20px;
    height: 20px;
    padding: 0;

    cursor: pointer;
    
}
.slick-dots li button
{
    
    display: block;

    width: 0px;
    height: 0px;
    padding-right:0px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}


.slick-dots li button:hover,
.slick-dots li button:focus
{
    outline: none;
    
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before
{
    opacity: 1;

    
}
.slick-dots li button:before
{
    font-family: 'slick';
    font-size: 40px;
    line-height: 20px;

    position: absolute;
    top: 25px;
    left: -30px;

    width: 20px;
    height: 20px;

    content: '•';
    text-align: center;

    opacity: .25;
    color: white;


    display: flex;
    justify-content: center;
    

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before
{
    
    color: white;
    opacity:1;
}


